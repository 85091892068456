import React, { useState } from "react";

export default function BMICalculator() {
  const [weight, setWeight] = useState(70);
  const [height, setHeight] = useState(170); // height in cm
  const [bmi, setBmi] = useState(null);
  const [category, setCategory] = useState("");

  const calculateBMI = () => {
    if (height > 0 && weight > 0) {
      const bmiValue = (weight / (height * height) * 10000).toFixed(2); // formula adjusted for height in cm
      setBmi(bmiValue);
      categorizeBMI(bmiValue);
    } else {
      setBmi(null);
      setCategory("Invalid Input");
    }
  };

  const categorizeBMI = (bmiValue) => {
    if (bmiValue < 18.5) setCategory("Underweight");
    else if (bmiValue >= 18.5 && bmiValue < 24.9) setCategory("Normal weight");
    else if (bmiValue >= 25 && bmiValue < 29.9) setCategory("Overweight");
    else setCategory("Obese");
  };

  return (
    <>
      {/* SEO Meta Tags */}
      <head>
        <meta name="description" content="BMI Calculator to calculate body mass index (BMI) using body mass calculation formula. Calculate body index, BMI body mass index, body weight index, and BMI ratio with our easy-to-use body mass index calculator." />
        <meta name="keywords" content="bmi formula, calculate bmi formula, body index calculator, body mass index formula, body bmi calculator, bmi body mass index, body weight index calculator, bio mass index, biomass index calculator, bmi bmi calculator, bmi calculator 2, bmi cl, bmi index formula, body index formula, body mass calculation formula, body mass formula, body mass index bmi calculator, body mass ratio, compute bmi calculator, mass index formula" />
        <meta name="robots" content="index, follow" />
        <title>BMI Calculator - Body Mass Index Formula & Body Mass Calculation</title>
      </head>

      <div style={{ display: "flex", flexWrap: "wrap", padding: "20px" }}>
        <div style={{ width: "100%", maxWidth: "70%", padding: "20px" }}>
          <h1 style={{ fontSize: "2em", textAlign: "left" }}>Body Mass Index (BMI) Calculator</h1>
          <p style={{ fontSize: "1.2em", textAlign: "left" }}>Calculate your BMI using the formula: weight (kg) / [height (cm)]² × 10,000</p>

          <div style={{ marginBottom: "10px" }}>
            <label style={{ fontSize: "1.1em" }}>Weight (kg): </label>
            <input
              type="number"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              style={{ fontSize: "1em", padding: "5px", width: "100%" }}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label style={{ fontSize: "1.1em" }}>Height (cm): </label>
            <input
              type="number"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              style={{ fontSize: "1em", padding: "5px", width: "100%" }}
            />
          </div>

          <button
            onClick={calculateBMI}
            style={{ fontSize: "1.2em", padding: "10px 20px", cursor: "pointer", width: "100%" }}
          >
            Calculate BMI
          </button>

          {bmi && (
            <div style={{ marginTop: "20px" }}>
              <h2>Your BMI: {bmi}</h2>
              <h3>Category: {category}</h3>
            </div>
          )}

          <h2 style={{ fontSize: "1.5em", marginTop: "40px" }}>Understanding BMI</h2>
          <ul style={{ fontSize: "1.1em" }}>
            <li><strong>BMI Formula:</strong> weight (kg) / [height (cm)]² × 10,000</li>
            <li><strong>Body Index Calculator:</strong> Determine your BMI quickly.</li>
            <li><strong>Body Mass Index (BMI) Formula:</strong> Standard formula for calculating BMI.</li>
            <li><strong>Body BMI Calculator:</strong> Simple tool to compute BMI.</li>
            <li><strong>Biomass Index Calculator:</strong> Also referred to as BMI calculator.</li>
            <li><strong>BMI Index Formula:</strong> Another way to refer to BMI calculation.</li>
            <li><strong>Body Mass Ratio:</strong> Key factor in BMI computation.</li>
          </ul>
        </div>

        {/* Right Sidebar with Simple Ad */}
        <div style={{ width: "100%", maxWidth: "25%", padding: "20px", backgroundColor: "#f7f7f7", borderRadius: "10px", textAlign: "center", marginTop: "20px" }}>
          <h3 style={{ fontSize: "1.5em" }}>Download Free Diabetes Sugar Tracker App</h3>
          <a
            href="https://apps.apple.com/np/app/diabetes-sugar-tracker/id6474657996"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "inline-block",
              padding: "10px 20px",
              backgroundColor: "#1E90FF",
              color: "#fff",
              fontSize: "1.1em",
              borderRadius: "5px",
              textDecoration: "none",
              marginTop: "20px"
            }}
          >
            Download Now
          </a>
        </div>
      </div>
    </>
  );
}
