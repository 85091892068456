import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";

const BloodGlucoseLog = () => {
  const [logs, setLogs] = useState(
    Array(7).fill().map(() => ({
      date: "",
      beforeBreakfast: "",
      afterBreakfast: "",
      beforeLunch: "",
      afterLunch: "",
      beforeDinner: "",
      afterDinner: "",
      beforeSleep: "",
      afterSleep: "",
      notes: ""
    }))
  );
  const [unit, setUnit] = useState("mg/dL"); // Default unit

  useEffect(() => {
    const savedLogs = localStorage.getItem("bloodGlucoseLogs");
    if (savedLogs) {
      setLogs(JSON.parse(savedLogs));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("bloodGlucoseLogs", JSON.stringify(logs));
  }, [logs]);

  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const handleChange = (index, field, value) => {
    const newLogs = [...logs];
    newLogs[index] = { ...newLogs[index], [field]: value };
    setLogs(newLogs);
  };

  const clearLogs = () => {
    setLogs(
      Array(7).fill().map(() => ({
        date: "",
        beforeBreakfast: "",
        afterBreakfast: "",
        beforeLunch: "",
        afterLunch: "",
        beforeDinner: "",
        afterDinner: "",
        beforeSleep: "",
        afterSleep: "",
        notes: ""
      }))
    );
  };

  const handleUnitChange = (e) => {
    setUnit(e.target.value);
  };

  const downloadCSV = () => {
    let csvContent = `Unit: ${unit}\n`;
    csvContent += "Date,Before Breakfast,After Breakfast,Before Lunch,After Lunch,Before Dinner,After Dinner,Before Sleep,After Sleep,Notes\n";
    logs.forEach(log => {
      csvContent += `"${log.date}","${log.beforeBreakfast}","${log.afterBreakfast}","${log.beforeLunch}","${log.afterLunch}","${log.beforeDinner}","${log.afterDinner}","${log.beforeSleep}","${log.afterSleep}","${log.notes}"\n`;
    });
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "blood_glucose_log.csv";
    a.click();
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(logs);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Blood Glucose Log");
    XLSX.writeFile(workbook, "blood_glucose_log.xlsx");
  };

  return (
    <>
      {/* SEO Meta Tags */}
      <head>
        <meta
          name="description"
          content="Free diabetic log sheet, blood glucose log sheet excel, printable blood glucose log sheet, blood sugar monitoring chart, glucose log sheet, free printable blood glucose log sheet, blood sugar recording chart, blood glucose log printable, blood glucose log sheet pdf, blood sugar level monitoring chart, glucose record sheet, diabetes log sheet, free printable blood sugar chart, and more for managing blood sugar levels."
        />
        <meta
          name="keywords"
          content="free diabetic log sheet, blood glucose log sheet excel, blood glucose log sheet printable, blood glucose log template, blood glucose sheet, blood sugar monitoring chart pdf, glucose log sheet, free printable blood glucose log sheet, blood sugar log sheet pdf, blood sugar recording chart, free printable blood sugar log sheet, free blood glucose log sheet, blood sugar monitoring chart, blood glucose log printable, blood glucose log sheet pdf, blood sugar and food log printable, blood sugar level monitoring chart, glucose logs, printable blood sugar levels log, sugar level monitoring chart, blood glucose monitoring sheet, blood sugar log sheets pdf, blood sugar monitoring sheet, diabetes log printable, diabetes log sheet free printable, free printable blood sugar chart, free printable blood sugar log sheet monthly, glucose monitoring sheet, glucose record sheet, sugar level recording chart"
        />
        <meta name="robots" content="index, follow" />
        <title>Large Print Free Printable Blood Sugar Log Sheet, Blood Glucose Log Sheet Printable, Blood Glucose Sheet, Blood Glucose Log Template ,blood glucose sheet</title>
      </head>

      <div className="container mt-4">
        <h2 className="text-center">Blood Glucose Sheet</h2>

        {/* Download Link for Diabetes Sugar Tracker */}
        <div className="d-flex justify-content-end mb-3">
          <a
            href="https://apps.apple.com/np/app/diabetes-sugar-tracker/id6474657996"
            target="_blank"
            className="btn btn-link"
            style={{ fontSize: "1.1rem" }}
          >
            Download Free Diabetes Sugar Tracker App
          </a>
        </div>

        {/* Unit Selection */}
        <div className="mb-4">
          <label>Select Unit: </label>
          <select className="form-select" value={unit} onChange={handleUnitChange}>
            <option value="mg/dL">mg/dL</option>
            <option value="mmol/L">mmol/L</option>
          </select>
        </div>

        <table className="table table-bordered table-striped">
          <thead className="table-dark">
            <tr>
              <th>Day</th>
              <th>Date (YYYY-MM-DD)</th>
              <th>Before Breakfast</th>
              <th>After Breakfast</th>
              <th>Before Lunch</th>
              <th>After Lunch</th>
              <th>Before Dinner</th>
              <th>After Dinner</th>
              <th>Before Sleep</th>
              <th>After Sleep</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {days.map((day, index) => (
              <tr key={index}>
                <td>{day}</td>
                <td><input type="date" className="form-control" value={logs[index].date} onChange={(e) => handleChange(index, "date", e.target.value)} /></td>
                <td><input type="number" className="form-control" maxLength="4" value={logs[index].beforeBreakfast} onChange={(e) => handleChange(index, "beforeBreakfast", e.target.value)} /></td>
                <td><input type="number" className="form-control" maxLength="4" value={logs[index].afterBreakfast} onChange={(e) => handleChange(index, "afterBreakfast", e.target.value)} /></td>
                <td><input type="number" className="form-control" maxLength="4" value={logs[index].beforeLunch} onChange={(e) => handleChange(index, "beforeLunch", e.target.value)} /></td>
                <td><input type="number" className="form-control" maxLength="4" value={logs[index].afterLunch} onChange={(e) => handleChange(index, "afterLunch", e.target.value)} /></td>
                <td><input type="number" className="form-control" maxLength="4" value={logs[index].beforeDinner} onChange={(e) => handleChange(index, "beforeDinner", e.target.value)} /></td>
                <td><input type="number" className="form-control" maxLength="4" value={logs[index].afterDinner} onChange={(e) => handleChange(index, "afterDinner", e.target.value)} /></td>
                <td><input type="number" className="form-control" maxLength="4" value={logs[index].beforeSleep} onChange={(e) => handleChange(index, "beforeSleep", e.target.value)} /></td>
                <td><input type="number" className="form-control" maxLength="4" value={logs[index].afterSleep} onChange={(e) => handleChange(index, "afterSleep", e.target.value)} /></td>
                <td><input type="text" className="form-control" style={{ height: "50px", width: "200px" }} value={logs[index].notes} onChange={(e) => handleChange(index, "notes", e.target.value)} /></td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Clear and Download Buttons */}
        <div className="d-grid gap-2">
          <button className="btn btn-danger mb-2" onClick={clearLogs}>Clear All</button>
          <button className="btn btn-primary mb-2" onClick={downloadCSV}>Download CSV</button>
          {/* <button className="btn btn-secondary mb-2" onClick={downloadPDF}>Download PDF</button> */}
          {/* <button className="btn btn-success" onClick={downloadExcel}>Download Excel</button> */}
        </div>
      </div>
    </>
  );
};

export default BloodGlucoseLog;
