import React, { useState } from "react";
import Blogs from "./blogs";
import suga from "../images/sugar.png";
import note from "../images/note.png";
import weight from "../images/weights.png";
import medi from "../images/medications.png";
import Main from "../components/main";
import Product_card from "../components/product_card";

export default function About({ children }) {
  let content =
    "Diabetes Sugar Tracker is an app designed specifically for diabetes patients to manage diabetes daily. You can track blood sugar levels, record diet and symptoms, track weight, and manage medications all in a single app.";
  const [activeContent, setActiveContent] = useState("default");

  function handleProductClick(content) {
    setActiveContent(content);
  }

  return (
    <Main>
      {/* SEO Meta Information */}
      <head>
      <title>Diabetes Sugar Tracker App - Best Free Diabetes App for iPhone | Type 2 Diabetics App, Gestational Diabetes App & Pre-Diabetics App</title>
      <meta name="description" content="Track and manage your diabetes effortlessly with the best free diabetes app for iPhone. The Diabetes Sugar Tracker app is the top Type 2 Diabetics app, Gestational Diabetes app, and Pre Diabetics app. Monitor blood sugar levels, diet, symptoms, weight, and medications—all in one place for better diabetes management. Looking for the best type 2 diabetes app or apps for prediabetes or type 2 diabetes tracker ? Download the best free app for Type 2 Diabetes today" />
        <meta name="keywords" content="Diabetes Sugar Tracker App, Diabetes Management App, Blood Sugar Monitor, Glucose Tracking App, Diabetes Weight Tracker, Medication Management for Diabetes, Blood Glucose Tracker, Track Blood Sugar, Blood Sugar Log App, Diabetes Health Tracker, Daily Diabetes Tracker, Blood Sugar Tracking, Diabetes Tracker, Blood Sugar Tracker App, Diabetes App, Diabetes Sugar App, Diabetes Management App, Diabetes Sugar Tracker App, Track Blood Sugar, Diabetes Health Tracker, Diabetes Tracker, Blood Sugar Tracker App, Best Diabetes App, Track Diabetes, Glucose Tracker, Blood Glucose Tracker, Sugar Tracker, Blood Sugar Tracking App, Glucose Tracker App, Sugar Tracking Device, Track Your Blood Sugar, Monitor Blood Sugar with Phone, Sugar Level Tracker, Sugar Tracker App, Track Glucose, Blood Sugar Level Tracker , Track Blood Glucose iPhone, iPhone Glucose App, iPhone App to Check Blood Sugar, Best Glucose App for iPhone, Best iPhone App for Tracking Blood Sugar, Sugar iOS, Sugar Tracker App iPhone, Sugar App iOS, Free App iOS Diabetes, Diabetes Free App iOS, Sugar Tracker App iOS, Sugar Tracker App iOS, Blood Sugar App for iPhone, Glucose App for iPhone, Best Free Diabetes App for iPhone, Glucose Monitor App for iPhone,Type 2 Diabetics app, Gestational Diabetes app ,Pre Diabetes Log, Type 2 Diabetes Log , Gestational Diabetes Log ,type 2 diabetes app ,apps for prediabetes ,type 2 diabetes tracker ,best app for type 2 diabetes,
best free app for type 2 diabetes" />      </head>
      
     
      
      <Blogs onClick={handleProductClick} />

<br></br>
      <ul>
  <li><h5>The Best Diabetes Sugar Tracker App for Diabetes Management | Best Free Diabetes App for iPhone</h5></li>
  <br></br>
  <li><h5>Best Type 2 Diabetes App, Gestational Diabetes App & Pre-Diabetes App</h5></li>
  <br></br>
  <li><h5>Best For Pre Diabetes Log, Type 2 Diabetes Log & Gestational Diabetes Log</h5></li>
</ul>


    

      <div>{children}</div>
      <div>
        <Product_card activeContent="" content={content} />
      </div>

      <h2 className="text-primary">Features of the Diabetes Sugar Tracker App for  iphone </h2>

      <div className="card-group m-auto" style={{ width: "100%" }}>
        <div className="card border-0 hover-shadow">
          <div className="card-body">
            <img src={suga} className="img-fluid m-2" style={{ width: "60px" }} alt="Blood Sugar Tracking" />
            <h3 className="card-title">Blood Sugar Tracking</h3>
            <p className="card-text text-sm">
              The Diabetes Sugar Tracker app allows you to easily add, update, or delete blood sugar readings, record essential details such as date, time, and blood sugar level, specify the unit of measurement in mg/dL or mmol/L, and categorize measurements based on meal times, including After Lunch, Before Lunch, After Dinner, Before Dinner, Breakfast, After or Before Sleep, Fasting, and other custom options.
            </p>
          </div>
        </div>

        <div className="card border-0 hover-shadow">
          <div className="card-body">
            <img src={note} className="m-2" style={{ width: "60px" }} alt="Add Notes" />
            <h3 className="card-title">Add Notes</h3>
            <p className="card-text">
              The Diabetes Sugar Tracker app enables you to capture important information such as diet and symptoms by adding notes and includes the date with your notes for accurate record-keeping and tracking.
            </p>
          </div>
        </div>

        <div className="card border-0 hover-shadow">
          <div className="card-body">
            <img src={weight} className="m-2" style={{ width: "60px" }} alt="Track Weight" />
            <h3 className="card-title">Track Weight</h3>
            <p className="card-text">
              The Diabetes Sugar Tracker app allows you to record weight, input details such as date and weight, choose the unit of measurement (Kilogram or Pound), and add additional notes to provide relevant details.
            </p>
          </div>
        </div>

        <div className="card border-0 hover-shadow">
          <div className="card-body">
            <img src={medi} className="m-2" style={{ width: "60px" }} alt="Manage Medication" />
            <h3 className="card-title">Manage Medication</h3>
            <p className="card-text">
              The Diabetes Sugar Tracker app enables you to effectively manage your medication information by allowing you to add new medications, specifying the unit of measure—units, mg, mL, or tablets, record dosage details in alignment with the selected unit of measure, and add additional notes related to medication.
            </p>
          </div>
        </div>
      </div>
    </Main>
  );
}
