import React from "react";
import { Routes, Route } from "react-router-dom";
import InnovationLab from "./pages/innovationlab";
import Aboutus from "./pages/aboutus";
import WhatWeDo from "./pages/whatwedo";
import Blogs from "./pages/blogs";
import Aiml from "./pages/aiml";
import DataAnalytics from "./pages/dataAnalytics";
import Startup from "./pages/startup";
import FellowShip from "./pages/fellowship";
import Careers from "./pages/careers";
import PageNotFound from "./pages/pagenotfound";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacypolicy";
import Support from "./pages/support";
import Follow_us from "./pages/followus";
import Benefit from "./pages/benefit";
import About from "./pages/about";
import Howitworks from "./pages/howitworks";
import Faq from "./pages/faq";
import A1CCalculator from "./pages/A1CCalculator";
import AnionGapCalculator from "./pages/AnionGapCalculator";
import BloodSugarConversion from "./pages/BloodSugarConversion";
import Blogsprediabetes from "./pages/blogsprediabetes";
import Blogstype2diabetes from "./pages/blogstype2diabetes";
import BMICalculator from "./pages/BMICalculator";
import BloodGlucoseLog from "./pages/BloodGlucoseLog";
import Type2DiabetesInfo from "./pages/Type2DiabetesInfo";
function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Aboutus />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/what-we-do" element={<WhatWeDo />} />
        <Route path="/innovation-lab" element={<InnovationLab />} />
        <Route path="/services/AI/ML" element={<Aiml />} />
        <Route path="/services/data-analytics" element={<DataAnalytics />} />
        <Route path="/services/javatraining" element={<Startup />} />
        {/* Define the route for A1C Conversion */}
        <Route path="/a1c-conversion-calculator" element={<A1CCalculator />} />
        <Route path="/anion-gap-calculation" element={<AnionGapCalculator />} />
        {/* You can add more routes here */}

        <Route path="/blood-sugar-conversion" element={<BloodSugarConversion />} />

        <Route path="/diabetes-sugar-tracking-app" element={<About />} />
        <Route path="/diabetes-sugar-tracking-app/benefit" element={<Benefit />} />
        <Route path="/diabetes-sugar-tracking-app/about" element={<About />} />
        <Route path="/diabetes-sugar-tracking-app/howitworks" element={<Howitworks />} />
        <Route path="/diabetes-sugar-tracking-app/faq" element={<Faq />} />
        <Route path="/diabetes-sugar-tracking-app/followus" element={<Follow_us />} />

        <Route path="/internship" element={<FellowShip />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/apps-for-prediabetes" element={<Blogsprediabetes />} />
        <Route path="/app-for-type2-diabetes" element={<Blogstype2diabetes />} />
        <Route path="/body-mass-calculation-formula" element={<BMICalculator />} />
        <Route path="/blood-glucose-sheet" element={<BloodGlucoseLog />} />
        <Route path="/type-2-diabetes-patient-info" element={<Type2DiabetesInfo />} />


        <Route path="/*" element={<PageNotFound />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<Support />} />
      </Routes>
    </div>
  );
}

export default App;
