import React from "react";
import { Link } from "react-router-dom";
import yo from "../images/you.png"
export default function Blogs({ onClick }) {
  return (
    <div className="navbar-container">
      <div className="navbar pt-5 pb-4 bg-primary d-flex justify-content-center">
        <ul className="nav nav-underline mt-5">
          <Link
            to="/diabetes-sugar-tracking-app/about"
            className="text-white text-decoration-none"
          >
            About
          </Link>
          <Link
            to="/diabetes-sugar-tracking-app/benefit"
            className="text-white text-decoration-none"
          >
            Benefits
          </Link>
          <Link
            to="/diabetes-sugar-tracking-app/howitworks"
            className="text-white text-decoration-none"
          >
            How it Works
          </Link>
          <Link
            to="/diabetes-sugar-tracking-app/faq"
            className="text-white text-decoration-none"
          >
            FAQ
          </Link>
          <Link
            to="/diabetes-sugar-tracking-app/followus"
            className="text-white text-decoration-none"
          >
            Follow us
          </Link>

   
        </ul>
        <button type="button" class="btn btn-primary bg-white mt-5 " style={{marginLeft:'10px'}}>
          <a href='https://apps.apple.com/np/app/diabetes-sugar-tracker/id6474657996' className="text-primary text-decoration-none">Download App
</a></button>

<button type="button" class="btn btn-primary bg-white mt-5 " style={{marginLeft:'10px'}}>
          <a href='https://dakshyaai.com/a1c-conversion-calculator' className="text-primary text-decoration-none">A1c Level Calculator
</a></button>

<button type="button" class="btn btn-primary bg-white mt-5 " style={{marginLeft:'10px'}}>
          <a href='https://dakshyaai.com/anion-gap-calculation' className="text-primary text-decoration-none">Anion Gap Calculator 
</a></button>

<button type="button" class="btn btn-primary bg-white mt-5 " style={{marginLeft:'10px'}}>
          <a href='https://dakshyaai.com/blood-sugar-conversion' className="text-primary text-decoration-none">Blood Sugar Conversion 
</a></button>

<button type="button" class="btn btn-primary bg-white mt-5 " style={{marginLeft:'10px'}}>
          <a href='https://dakshyaai.com/body-mass-calculation-formula' className="text-primary text-decoration-none">BMI Calculator
</a></button>

<button type="button" class="btn btn-primary bg-white mt-5 " style={{marginLeft:'10px'}}>
          <a href='https://dakshyaai.com/blood-glucose-sheet' className="text-primary text-decoration-none">Glucose Logs
</a></button>

      </div>
    </div>
  );
}
